export const ProjectsList = [
  {
    name: "UNM Student Resource Mobile App",
    description:
      "UNM-SR is a resource application built for students of the University of New Mexico. The goal of this app is to connect students to clubs, organizations, resources and events on campus. For new and continuing students, finding and connecting with communities and friends with similar interests could be challenging especially in recently times due to the COVID-19 pandemic restrictions.",
    githubRepo: "https://github.com/aycarl/unmsr",
    website: "https://expo.io/@aycarl/projects/unmsr",
    toolsUsed: ["React JS", "React Native", "JavaScript", "Redux", "Firebase"],
  },
  {
    name: "Beautique",
    description: "An e-commerce website with payment integration",
    githubRepo: "https://github.com/aycarl/beautique",
    website: "http://beautique.herokuapp.com/",
    toolsUsed: ["React JS", "CSS", "HTML", "JavaScript", "Redux", "Firebase"],
  },
  {
    name: "Notices",
    description: "A virtual notice board",
    githubRepo: "https://github.com/aycarl/notices",
    website: "https://aycarl.github.io/notices/",
    toolsUsed: ["React JS", "CSS", "HTML", "JavaScript", "Redux", "Firebase"],
  },
];
